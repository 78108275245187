<template>
  <section class="home">
    <div class="container">
      <div class="content">
        <TextBlock
          class="text-block"
          :key="index"
          v-for="(textBlock, index) in textBlocks"
          :text-block="textBlock"
        />

        <div class="services">
          <div :key="index" v-for="(service, index) in services" class="item">
            <img :src="service.image" />
            <span class="title">{{ service.title }}</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import TextBlock from "@/components/TextBlock";
export default {
  name: "Home",
  components: { TextBlock },
  data() {
    return {
      services: [
        { image: "img/invest.png", title: "Invest" },
        { image: "img/consult.png", title: "Consult" },
        { image: "img/launch.png", title: "Launch" },
      ],
      textBlocks: [
        {
          title: "WE BUILD COMPANIES",
          text: `We work with early-stage companies where we can deliver the greatest
            impact in the areas of M&A activity, equity financing, product
            development and commercialization, strategic relationships, and
            growth strategy and execution.`,
          isShowDivider: true,
        },
        {
          title: "HOW WE WORK",
          text: `Being in your shoes, we understand that cash might be tight at times and we
           have worked in hybrid compensation models, 
           varying from hourly or monthly based engagements
            to equity-based engagements, or a combination of both.`,
          isShowDivider: false,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.container {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
  background-clip: border-box;
}

.content {
  margin: 80px 100px;
  padding-right: 0px;
  padding-left: 0px;

  @media screen and (max-width: 767px) {
    margin: 80px 80px;
  }
}

.text-block:not(:last-child) {
  margin-bottom: 50px;
}

.services {
  margin-top: 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 760px;

  .title {
    font-size: 20px;
    line-height: 40px;
    font-weight: 700;
    text-align: left;
    text-transform: uppercase;
    display: block;
  }
}
</style>
