<template>
  <article class="text-block">
    <div class="title">{{ textBlock.title }}</div>
    <div class="text">
      {{ textBlock.text }}
    </div>

    <div v-if="textBlock.isShowDivider" class="divider" />
  </article>
</template>

<script>
export default {
  name: "TextBlock",
  props: {
    textBlock: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.text-block {
  text-align: left;

  .title {
    color: #1a1b1f;
    margin-top: 25px;
    margin-bottom: 10px;
    font-size: 30px;
    line-height: 62px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .text {
    color: #1a1b1f;
    font-size: 20px;
    line-height: 40px;
    font-weight: 400;
  }

  .divider {
    padding: 30px 0;
    border-bottom: 1px solid #000;
  }
}
</style>
